<template>
  <div class="page-wrapper shifts__wrapper">
    <div class="container">
      <div class="page-inner">
        <div class="page-title-wrapper">
          <div class="page-title">Services</div>
          <div @click="showAddService" class="shifts__button button button--fill">Add New Service</div>
        </div>
        <div class="table">
          <div class="table__title">
            <div class="table__title-item">On/Off</div>
            <div class="table__title-item">Name</div>
            <div class="table__title-item">Price</div>
            <div class="table__title-item">Fuel Surcharge</div>
            <div class="table__title-item">Shifts</div>
          </div>
          <div class="table__main">
            <Service v-for="service in services" :key="service.id" :info="service" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Service from '@/views/Admin/Services/Service'

export default {
  name: 'Services',
  components: {Service},
  data: () => ({
    services: [
      {
        id: 1,
        active: true,
        name: 'Day Skid Rate',
        price: '$10',
        fuelSurcharge: true,
        shifts: [
          {id: 1, name: '🌙  Night Shift'},
          {id: 2, name: '🌈  Pride Shift'}
        ]
      },
      {
        id: 2,
        active: true,
        name: 'Day Skid Rate',
        price: '$10',
        fuelSurcharge: true,
        shifts: [
          {id: 1, name: '🌙  Night Shift'},
          {id: 2, name: '🌈  Pride Shift'}
        ]
      },
      {
        id: 3,
        active: true,
        name: 'Day Skid Rate',
        price: '$10',
        fuelSurcharge: true,
        shifts: [
          {id: 1, name: '🌙  Night Shift'},
          {id: 2, name: '🌈  Pride Shift'}
        ]
      },
      {
        id: 4,
        active: true,
        name: 'Day Skid Rate',
        price: '$10',
        fuelSurcharge: true,
        shifts: [
          {id: 1, name: '🌙  Night Shift'},
          {id: 2, name: '🌈  Pride Shift'}
        ]
      },
      {
        id: 5,
        active: true,
        name: 'Day Skid Rate',
        price: '$10',
        fuelSurcharge: false,
        shifts: [
          {id: 1, name: '🌙  Night Shift'},
          {id: 2, name: '🌈  Pride Shift'}
        ]
      },
      {
        id: 6,
        active: true,
        name: 'Day Skid Rate',
        price: '$10',
        fuelSurcharge: true,
        shifts: [
          {id: 1, name: '🌙  Night Shift'},
          {id: 2, name: '🌈  Pride Shift'}
        ]
      },
      {
        id: 7,
        active: false,
        name: 'Day Skid Rate',
        price: '$10',
        fuelSurcharge: true,
        shifts: [
          {id: 1, name: '🌙  Night Shift'},
          {id: 2, name: '🌈  Pride Shift'}
        ]
      },
      {
        id: 8,
        active: true,
        name: 'Day Skid Rate',
        price: '$10',
        fuelSurcharge: false,
        shifts: [
          {id: 1, name: '🌙  Night Shift'},
          {id: 2, name: '🌈  Pride Shift'}
        ]
      },
      {
        id: 9,
        active: true,
        name: 'Day Skid Rate',
        price: '$10',
        fuelSurcharge: true,
        shifts: [
          {id: 1, name: '🌙  Night Shift'},
          {id: 2, name: '🌈  Pride Shift'}
        ]
      },
      {
        id: 10,
        active: true,
        name: 'Day Skid Rate',
        price: '$10',
        fuelSurcharge: true,
        shifts: [
          {id: 1, name: '🌙  Night Shift'},
          {id: 2, name: '🌈  Pride Shift'}
        ]
      },
      {
        id: 11,
        active: true,
        name: 'Day Skid Rate',
        price: '$10',
        fuelSurcharge: true,
        shifts: [
          {id: 1, name: '🌙  Night Shift'},
          {id: 2, name: '🌈  Pride Shift'}
        ]
      },
      {
        id: 12,
        active: true,
        name: 'Day Skid Rate',
        price: '$10',
        fuelSurcharge: true,
        shifts: [
          {id: 1, name: '🌙  Night Shift'},
          {id: 2, name: '🌈  Pride Shift'}
        ]
      },
    ]
  }),
  methods: {
    showAddService() {
      this.$root.$emit('ShowSidebar', 'AddService')
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'style';
</style>
